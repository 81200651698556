import { gql } from "@apollo/client";
import classNames from "classnames";
import SubpagesFilterDropdown from "components/FilterDropdown/SubpagesFilterDropdown";
import initNetYieldChart from "components/NetYieldChart";
import { BetterAlert as Alert } from "components/profile/Alert";
import React, { useMemo, useRef, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";

import { SecondaryButton } from "~/components/common/buttons";
import { PencilIcon } from "~/components/common/icons";
import {
  FundEntityOverviewFragment,
  SubpageOverviewFragment,
} from "~/components/Overview/OverviewPage.generated";
import {
  SUBPAGE_MENU_FRAGMENT,
  SubpagesMenu,
} from "~/components/Overview/SubpagesMenu";

import Subpage from "./Subpage";

export const SUBPAGE_OVERVIEW_FRAGMENT = gql`
  fragment SubpageOverview on SubpageInterface {
    id
    slug
    title
    description
  }
`;

export const FUND_ENTITY_OVERVIEW_FRAGMENT = gql`
  fragment FundEntityOverview on FundEntity {
    id
    name
    hasSubpages
    subpages {
      ...SubpageOverview
      ...SubpagesMenu
    }
    netYields {
      id
      year
      actualEnergy
      p50
      p90
    }
    projects {
      id
      name
      capacity
      type
      location {
        latitude
        longitude
      }
    }
  }

  ${SUBPAGE_OVERVIEW_FRAGMENT}
  ${SUBPAGE_MENU_FRAGMENT}
`;

interface OverviewPageProps {
  subject: FundEntityOverviewFragment;
  onViewSubpage: (subpage: SubpageOverviewFragment) => void;
}

export const OverviewPage = ({ subject, onViewSubpage }: OverviewPageProps) => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const projectGeoJson = useMemo(() => {
    const projects = (subject?.projects ?? []).filter(
      (p) => p.location.latitude && p.location.longitude
    );
    return {
      type: "projects",
      features: projects.map((project) => {
        return {
          type: "project",
          properties: {
            name: project.name,
            size: project.capacity,
          },
          geometry: {
            type: "Point",
            coordinates: [
              project.location.longitude,
              project.location.latitude,
            ],
          },
        };
      }),
    };
  }, [subject]);
  const parentNodeRef = useRef(null);

  if (!subject.subpages?.length) {
    let message = `Please contact your administrator for access to details for ${subject.name}.`;
    if (!subject.hasSubpages)
      message = `Overview is missing. Click on "Documents" to access the dataroom for ${subject.name}.`;
    return <Alert message={`${message}`} classNames={["alert-warning"]} />;
  }

  const firstSubpage = subject.subpages.length ? subject.subpages[0] : null;
  const trimmedPath = history.location.pathname.replace(/\/$/, ""); // Remove trailing slash
  const currentSubpageSlug = trimmedPath.substr(
    trimmedPath.lastIndexOf("/") + 1
  );

  const subpageSlugs = subject.subpages.map((subpage) => subpage.slug);
  const currentIndex = subpageSlugs.indexOf(currentSubpageSlug);
  const previousPageSlug =
    currentIndex > 0 ? subpageSlugs[currentIndex - 1] : null;
  const nextPageSlug =
    currentIndex < subpageSlugs.length - 1
      ? subpageSlugs[currentIndex + 1]
      : null;

  const subpageChoices = [];
  for (let i = 0; i < subject.subpages.length; i++) {
    subpageChoices.push({
      key: subject.subpages[i].slug,
      value: subject.subpages[i].title,
      item: subject.subpages[i],
    });
  }

  if (subject.netYields?.length) {
    // Not a React component, need to initialize google charts
    initNetYieldChart("net-yield-chart", subject.netYields);
  }
  return (
    <div>
      <div className="row mb-4" ref={parentNodeRef}>
        <div className="col-lg-9 overflow-x subpage-markdown">
          <Switch>
            {subject.subpages.map((subpage) => (
              <Route
                key={`r-${subpage.id}`}
                path={`/overview/${subpage.slug}`}
                render={() => (
                  <Subpage
                    subject={subject}
                    projectsGeoJson={projectGeoJson}
                    subpage={subpage}
                    onView={onViewSubpage}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                )}
              />
            ))}
            <Redirect to={`/overview/${firstSubpage?.slug}`} />
          </Switch>
        </div>
        <div className="col-lg-3 subpage-table-of-contents">
          <SubpagesMenu
            currentSubpageSlug={currentSubpageSlug}
            subpages={subject.subpages}
            parentNodeRef={parentNodeRef}
            isEditing={isEditing}
          />
        </div>
      </div>
      <hr />
      <div className="d-flex align-items-center">
        <SubpagesFilterDropdown
          selectedPage={currentSubpageSlug}
          items={subpageChoices}
          title="Table of Contents"
          onSelected={(selected) => {
            if (selected[0] !== currentSubpageSlug) {
              history.push(selected[0]);
            }
          }}
          disabled={isEditing}
        />
        <SecondaryButton
          onClick={() => setIsEditing(!isEditing)}
          className="ml-2"
          disabled={isEditing}
        >
          <PencilIcon />
          <span>{isEditing ? "Editing..." : "Edit Subpage"}</span>
        </SecondaryButton>
      </div>
      <div className="d-flex mt-2">
        <div className="subpage-pagination ml-auto">
          <Link
            to={`/overview/${previousPageSlug}`}
            className={classNames(
              "btn",
              "btn-outline-primary",
              "btn-icon",
              "previous-page",
              "custom-margin-right",
              { disabled: isEditing || previousPageSlug === null }
            )}
          />
          <Link
            to={`/overview/${nextPageSlug}`}
            className={classNames(
              "btn",
              "btn-outline-primary",
              "btn-icon",
              "next-page",
              { disabled: isEditing || nextPageSlug === null }
            )}
          />
        </div>
      </div>
    </div>
  );
};
