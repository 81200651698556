// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { SubpageOverview_HoldCoSubpage_Fragment, SubpageOverview_Subpage_Fragment } from './OverviewPage.generated';
import { gql } from '@apollo/client';
import { SubpageOverviewFragmentDoc } from './OverviewPage.generated';
import * as Apollo from '@apollo/client';
export type UpdateSubpageMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  description: Types.Scalars['String'];
}>;


export type UpdateSubpageMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateSubpage?: Types.Maybe<(
    { readonly __typename: 'UpdateSubpageMutation' }
    & { readonly subpage?: Types.Maybe<(
      { readonly __typename: 'HoldCoSubpage' }
      & SubpageOverview_HoldCoSubpage_Fragment
    ) | (
      { readonly __typename: 'Subpage' }
      & SubpageOverview_Subpage_Fragment
    )> }
  )> }
);

export type CreateTimelineMutationVariables = Types.Exact<{
  payload: Types.CreateTimelinePayload;
}>;


export type CreateTimelineMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createTimeline?: Types.Maybe<(
    { readonly __typename: 'CreateTimelineMutation' }
    & { readonly timeline?: Types.Maybe<(
      { readonly __typename: 'Timeline' }
      & Pick<Types.Timeline, 'id'>
    )> }
  )> }
);


export const UpdateSubpageDocument = gql`
    mutation UpdateSubpage($id: ID!, $description: String!) {
  updateSubpage(subpageId: $id, description: $description) {
    subpage {
      ...SubpageOverview
    }
  }
}
    ${SubpageOverviewFragmentDoc}`;
export type UpdateSubpageMutationFn = Apollo.MutationFunction<UpdateSubpageMutation, UpdateSubpageMutationVariables>;

/**
 * __useUpdateSubpageMutation__
 *
 * To run a mutation, you first call `useUpdateSubpageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubpageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubpageMutation, { data, loading, error }] = useUpdateSubpageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateSubpageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubpageMutation, UpdateSubpageMutationVariables>) {
        return Apollo.useMutation<UpdateSubpageMutation, UpdateSubpageMutationVariables>(UpdateSubpageDocument, baseOptions);
      }
export type UpdateSubpageMutationHookResult = ReturnType<typeof useUpdateSubpageMutation>;
export type UpdateSubpageMutationResult = Apollo.MutationResult<UpdateSubpageMutation>;
export type UpdateSubpageMutationOptions = Apollo.BaseMutationOptions<UpdateSubpageMutation, UpdateSubpageMutationVariables>;
export const CreateTimelineDocument = gql`
    mutation CreateTimeline($payload: CreateTimelinePayload!) {
  createTimeline(payload: $payload) {
    timeline {
      id
    }
  }
}
    `;
export type CreateTimelineMutationFn = Apollo.MutationFunction<CreateTimelineMutation, CreateTimelineMutationVariables>;

/**
 * __useCreateTimelineMutation__
 *
 * To run a mutation, you first call `useCreateTimelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimelineMutation, { data, loading, error }] = useCreateTimelineMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateTimelineMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimelineMutation, CreateTimelineMutationVariables>) {
        return Apollo.useMutation<CreateTimelineMutation, CreateTimelineMutationVariables>(CreateTimelineDocument, baseOptions);
      }
export type CreateTimelineMutationHookResult = ReturnType<typeof useCreateTimelineMutation>;
export type CreateTimelineMutationResult = Apollo.MutationResult<CreateTimelineMutation>;
export type CreateTimelineMutationOptions = Apollo.BaseMutationOptions<CreateTimelineMutation, CreateTimelineMutationVariables>;