// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Decimal: any;
  JSONString: any;
  UUID: any;
  Upload: File;
};

export type Category = {
  readonly __typename: 'Category';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly order: Scalars['Int'];
  readonly isDevelopment: Scalars['Boolean'];
  readonly isOperating: Scalars['Boolean'];
  readonly children?: Maybe<ReadonlyArray<Category>>;
  readonly parentId?: Maybe<Scalars['ID']>;
  readonly rowNumber: Scalars['Int'];
};

export type Checklist = {
  readonly __typename: 'Checklist';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly items?: Maybe<ReadonlyArray<Item>>;
  readonly categories?: Maybe<ReadonlyArray<Category>>;
};

export enum ChecklistItemStatus {
  StateRequested = 'STATE_REQUESTED',
  SubmittedForReview = 'SUBMITTED_FOR_REVIEW',
  UnderReview = 'UNDER_REVIEW',
  ThirdPartyReview = 'THIRD_PARTY_REVIEW',
  FollowUpNeeded = 'FOLLOW_UP_NEEDED',
  SignatureRequired = 'SIGNATURE_REQUIRED',
  Done = 'DONE'
}

export type Company = {
  readonly __typename: 'Company';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly logo?: Maybe<Scalars['String']>;
  readonly logoUrl?: Maybe<Scalars['String']>;
};

export type CompanyAggs = {
  readonly __typename: 'CompanyAggs';
  readonly id: Scalars['ID'];
  readonly company?: Maybe<Company>;
  readonly userCount?: Maybe<Scalars['Int']>;
  readonly sessionCount?: Maybe<Scalars['Int']>;
  readonly downloadCount?: Maybe<Scalars['Int']>;
  readonly viewCount?: Maybe<Scalars['Int']>;
};

export type ConfirmImportProjectsMutation = {
  readonly __typename: 'ConfirmImportProjectsMutation';
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type ConfirmUploadInput = {
  readonly documentId: Scalars['ID'];
  readonly version: Scalars['String'];
};

export type ConfirmUploadsResult = {
  readonly __typename: 'ConfirmUploadsResult';
  readonly files: ReadonlyArray<Document>;
  readonly failedIds: ReadonlyArray<Scalars['ID']>;
};

export type ContactInput = {
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly balancingAuthorityAreaName?: Maybe<Scalars['String']>;
};

export type CreateMilestoneMutation = {
  readonly __typename: 'CreateMilestoneMutation';
  readonly timeline?: Maybe<Timeline>;
};

export type CreateMilestonePayload = {
  readonly timelineId: Scalars['ID'];
};

export type CreatePortfolioDocumentTreeMutation = {
  readonly __typename: 'CreatePortfolioDocumentTreeMutation';
  readonly documentTree?: Maybe<DocumentTree>;
};

export type CreatePresignedFilesResult = {
  readonly __typename: 'CreatePresignedFilesResult';
  readonly url: Scalars['String'];
  readonly version: Scalars['String'];
  readonly file?: Maybe<Document>;
};

export type CreateSubpageMediaMutation = {
  readonly __typename: 'CreateSubpageMediaMutation';
  readonly subpage?: Maybe<GenericSubpageMedia>;
};

export type CreateTimelineMutation = {
  readonly __typename: 'CreateTimelineMutation';
  readonly timeline?: Maybe<Timeline>;
};

export type CreateTimelinePayload = {
  readonly subjectType: TimelineSubjectType;
  readonly subjectId: Scalars['ID'];
};




export type DeleteMilestoneMutation = {
  readonly __typename: 'DeleteMilestoneMutation';
  readonly timeline?: Maybe<Timeline>;
};

export type DeleteMilestonePayload = {
  readonly milestoneId: Scalars['ID'];
};

export type DeletedInvitationResult = {
  readonly __typename: 'DeletedInvitationResult';
  readonly token?: Maybe<Scalars['ID']>;
};

export type DeletedObjectResult = {
  readonly __typename: 'DeletedObjectResult';
  readonly id?: Maybe<Scalars['ID']>;
};

export enum DevelopmentStage {
  EarlyStage = 'EARLY_STAGE',
  MidStage = 'MID_STAGE',
  LateStage = 'LATE_STAGE',
  Complete = 'COMPLETE'
}

export type Document = {
  readonly __typename: 'Document';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly fileType?: Maybe<Scalars['String']>;
  readonly size?: Maybe<Scalars['Int']>;
  readonly modifiedTimestamp?: Maybe<Scalars['DateTime']>;
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly labels?: Maybe<ReadonlyArray<Label>>;
  readonly parentId?: Maybe<Scalars['ID']>;
  readonly currentVersion?: Maybe<Scalars['String']>;
  readonly filePath?: Maybe<Scalars['String']>;
  readonly dirPath?: Maybe<Scalars['String']>;
  readonly thumbnailUrl?: Maybe<Scalars['String']>;
  readonly previewUrl?: Maybe<Scalars['String']>;
  readonly permissions?: Maybe<ReadonlyArray<Maybe<DocumentPermission>>>;
  readonly link?: Maybe<Scalars['String']>;
  readonly children?: Maybe<ReadonlyArray<Document>>;
  readonly parent?: Maybe<Document>;
  readonly ancestors?: Maybe<ReadonlyArray<Maybe<Document>>>;
  readonly descendants?: Maybe<ReadonlyArray<Maybe<Document>>>;
};


export type DocumentDescendantsArgs = {
  search: Scalars['String'];
};

export type DocumentActivity = {
  readonly __typename: 'DocumentActivity';
  readonly id: Scalars['ID'];
  readonly activityType?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['DateTime']>;
  readonly user: UserType;
  readonly version?: Maybe<Scalars['String']>;
  readonly recordedSize?: Maybe<Scalars['Int']>;
  readonly recordedPath?: Maybe<Scalars['String']>;
  readonly document: Document;
  readonly userGroups?: Maybe<ReadonlyArray<UserGroup>>;
  readonly active?: Maybe<Scalars['Boolean']>;
};

export type DocumentAggs = {
  readonly __typename: 'DocumentAggs';
  readonly id: Scalars['ID'];
  readonly document?: Maybe<Document>;
  readonly downloadCount?: Maybe<Scalars['Int']>;
  readonly viewCount?: Maybe<Scalars['Int']>;
};

export type DocumentPermission = {
  readonly __typename: 'DocumentPermission';
  readonly id: Scalars['ID'];
  readonly principal?: Maybe<DocumentPrincipal>;
  readonly permissible?: Maybe<Scalars['Boolean']>;
};

export type DocumentPrincipal = UserGroup | Role;

export type DocumentTree = {
  readonly __typename: 'DocumentTree';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly type?: Maybe<DocumentType>;
  readonly permissionsType?: Maybe<PermissionsSetup>;
  readonly accessLogUrl?: Maybe<Scalars['String']>;
  readonly changeLogUrl?: Maybe<Scalars['String']>;
  readonly fileIndexUrl?: Maybe<Scalars['String']>;
  readonly canRename?: Maybe<Scalars['Boolean']>;
  readonly canEdit?: Maybe<Scalars['Boolean']>;
  readonly canDelete?: Maybe<Scalars['Boolean']>;
  readonly canEditPermissions?: Maybe<Scalars['Boolean']>;
  readonly canViewAccessLog?: Maybe<Scalars['Boolean']>;
  readonly canViewHistory?: Maybe<Scalars['Boolean']>;
  readonly allowRootFiles?: Maybe<Scalars['Boolean']>;
};

export enum DocumentType {
  OAndM = 'O_AND_M',
  Documents = 'DOCUMENTS',
  Reports = 'REPORTS'
}

export type ExportProjectImportTemplate = {
  readonly __typename: 'ExportProjectImportTemplate';
  readonly fileContent?: Maybe<Scalars['String']>;
  readonly error?: Maybe<Scalars['String']>;
};

export type FileInput = {
  readonly name: Scalars['String'];
  readonly size: Scalars['Int'];
  readonly parentId?: Maybe<Scalars['ID']>;
};

export type FileUrl = {
  readonly __typename: 'FileUrl';
  readonly url: Scalars['String'];
  readonly name: Scalars['String'];
};

export type FundEntity = {
  readonly id: Scalars['ID'];
  readonly org: Organization;
  readonly name: Scalars['String'];
  readonly slug: Scalars['String'];
  readonly status?: Maybe<WorkflowStatus>;
  readonly coverImage?: Maybe<Scalars['String']>;
  readonly systemSize?: Maybe<Scalars['Float']>;
  readonly systemSizeAc?: Maybe<Scalars['Float']>;
  readonly systemSizeEss?: Maybe<Scalars['Float']>;
  readonly dealManager?: Maybe<User>;
  readonly hasSubpages?: Maybe<Scalars['Boolean']>;
  readonly subpages?: Maybe<ReadonlyArray<SubpageInterface>>;
  readonly subpageMedia?: Maybe<ReadonlyArray<SubpageMediaInterface>>;
  readonly userGroups?: Maybe<ReadonlyArray<UserGroup>>;
  readonly netYields?: Maybe<ReadonlyArray<NetYield>>;
  readonly documentsTree?: Maybe<DocumentTree>;
  readonly reportsTree?: Maybe<DocumentTree>;
  readonly operatingStats?: Maybe<OperatingStats>;
  readonly projectsStats?: Maybe<ProjectsStats>;
  readonly qAndALogs?: Maybe<ReadonlyArray<QuestionLog>>;
  readonly projects?: Maybe<ReadonlyArray<Project>>;
};


export type FundEntityProjectsArgs = {
  search?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['String']>;
};

export enum FundEntityCategory {
  Acquired = 'ACQUIRED',
  Diligence = 'DILIGENCE',
  Market = 'MARKET'
}

export type FundEntityCollection = {
  readonly __typename: 'FundEntityCollection';
  readonly count?: Maybe<Scalars['Int']>;
  readonly stats?: Maybe<FundEntityCollectionStats>;
  readonly entities?: Maybe<ReadonlyArray<Maybe<FundEntity>>>;
};

export type FundEntityCollectionStats = {
  readonly __typename: 'FundEntityCollectionStats';
  readonly projectCount?: Maybe<Scalars['Int']>;
  readonly totalSize?: Maybe<Scalars['Int']>;
  readonly maxSize?: Maybe<Scalars['Int']>;
  readonly minSize?: Maybe<Scalars['Int']>;
};

export enum FundEntityType {
  Portfolio = 'PORTFOLIO',
  HoldingCompany = 'HOLDING_COMPANY'
}

export type GenericSubpageMedia = {
  readonly __typename: 'GenericSubpageMedia';
  readonly id: Scalars['ID'];
  readonly url: Scalars['String'];
};

export type HoldCoNotificationSetting = {
  readonly __typename: 'HoldCoNotificationSetting';
  readonly id: Scalars['ID'];
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type HoldCoSubpage = SubpageInterface & {
  readonly __typename: 'HoldCoSubpage';
  readonly title: Scalars['String'];
  readonly description: Scalars['String'];
  readonly order: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly slug: Scalars['String'];
};

export type HoldingCompany = FundEntity & {
  readonly __typename: 'HoldingCompany';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly slug: Scalars['String'];
  readonly status?: Maybe<WorkflowStatus>;
  readonly coverImage?: Maybe<Scalars['String']>;
  readonly systemSize?: Maybe<Scalars['Float']>;
  readonly enableReports: Scalars['Boolean'];
  readonly enablePortfolios: Scalars['Boolean'];
  readonly enableQAndA: Scalars['Boolean'];
  readonly org: Organization;
  readonly systemSizeAc?: Maybe<Scalars['Float']>;
  readonly systemSizeEss?: Maybe<Scalars['Float']>;
  readonly dealManager?: Maybe<User>;
  readonly hasSubpages?: Maybe<Scalars['Boolean']>;
  readonly subpages?: Maybe<ReadonlyArray<SubpageInterface>>;
  readonly subpageMedia?: Maybe<ReadonlyArray<SubpageMediaInterface>>;
  readonly userGroups?: Maybe<ReadonlyArray<UserGroup>>;
  readonly netYields?: Maybe<ReadonlyArray<NetYield>>;
  readonly documentsTree?: Maybe<DocumentTree>;
  readonly reportsTree?: Maybe<DocumentTree>;
  readonly operatingStats?: Maybe<OperatingStats>;
  readonly projectsStats?: Maybe<ProjectsStats>;
  readonly qAndALogs?: Maybe<ReadonlyArray<QuestionLog>>;
  readonly projects?: Maybe<ReadonlyArray<Project>>;
};


export type HoldingCompanyProjectsArgs = {
  search?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['String']>;
};

export type ImportProjectsMutation = {
  readonly __typename: 'ImportProjectsMutation';
  readonly errors?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly warnings?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly result?: Maybe<Scalars['JSONString']>;
};

export enum InstallationType {
  Roof = 'ROOF',
  Ground = 'GROUND',
  Carport = 'CARPORT',
  Mixed = 'MIXED',
  Floating = 'FLOATING'
}

export type InverterInput = {
  readonly modelId?: Maybe<Scalars['ID']>;
  readonly count?: Maybe<Scalars['Int']>;
};

export type InverterManufacturer = Manufacturer & {
  readonly __typename: 'InverterManufacturer';
  readonly slug: Scalars['String'];
  readonly name: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type InverterModel = ManufacturerModel & {
  readonly __typename: 'InverterModel';
  readonly slug: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly modelNumber: Scalars['String'];
  readonly manufacturer: Manufacturer;
};

export type Inverters = {
  readonly __typename: 'Inverters';
  readonly id: Scalars['ID'];
  readonly count?: Maybe<Scalars['Int']>;
  readonly inverterModelId?: Maybe<Scalars['ID']>;
  readonly modelId?: Maybe<Scalars['String']>;
};

export type InvitationsData = {
  readonly __typename: 'InvitationsData';
  readonly accepted?: Maybe<Scalars['Int']>;
  readonly waiting?: Maybe<Scalars['Int']>;
};

export type Item = {
  readonly __typename: 'Item';
  readonly id: Scalars['ID'];
  readonly link?: Maybe<Scalars['String']>;
  readonly status?: Maybe<ChecklistItemStatus>;
  readonly category?: Maybe<Category>;
  readonly commentCount?: Maybe<Scalars['Int']>;
  readonly projectId?: Maybe<Scalars['ID']>;
  readonly categoryId?: Maybe<Scalars['ID']>;
  readonly userId?: Maybe<Scalars['ID']>;
};


export type Label = {
  readonly __typename: 'Label';
  readonly id: Scalars['ID'];
  readonly label: Scalars['String'];
  readonly color: Scalars['String'];
};

export type LocationInput = {
  readonly address?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly zipCode?: Maybe<Scalars['String']>;
  readonly latitude?: Maybe<Scalars['Float']>;
  readonly longitude?: Maybe<Scalars['Float']>;
  readonly elevation?: Maybe<Scalars['Int']>;
  readonly importProtect?: Maybe<Scalars['Boolean']>;
};

export type Manufacturer = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly slug: Scalars['String'];
};

export type ManufacturerModel = {
  readonly modelNumber: Scalars['String'];
  readonly slug: Scalars['String'];
  readonly manufacturer?: Maybe<Manufacturer>;
};

export type Milestone = {
  readonly __typename: 'Milestone';
  readonly id: Scalars['ID'];
  readonly dueDate?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly body?: Maybe<Scalars['String']>;
  readonly order?: Maybe<Scalars['Int']>;
  readonly status?: Maybe<MilestoneStatus>;
};

export enum MilestoneStatus {
  Active = 'ACTIVE',
  Complete = 'COMPLETE'
}

export type ModifiedFolderOutput = {
  readonly __typename: 'ModifiedFolderOutput';
  readonly parents?: Maybe<ReadonlyArray<Maybe<Document>>>;
  readonly deletedIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
};

export enum MountingType {
  FixedTilt = 'FIXED_TILT',
  Single = 'SINGLE',
  DualAxis = 'DUAL_AXIS',
  Mixed = 'MIXED'
}

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly createTimeline?: Maybe<CreateTimelineMutation>;
  readonly createMilestone?: Maybe<CreateMilestoneMutation>;
  readonly updateMilestone?: Maybe<UpdateMilestoneMutation>;
  readonly deleteMilestone?: Maybe<DeleteMilestoneMutation>;
  readonly reorderMilestone?: Maybe<ReorderMilestoneMutation>;
  readonly uploadDataImport?: Maybe<SalesforceDataImport>;
  readonly createDuplicateReport?: Maybe<SalesforceDuplicateReport>;
  readonly createQuestionLog?: Maybe<QuestionLog>;
  readonly updateQuestionLog?: Maybe<QuestionLog>;
  readonly createQuestion?: Maybe<Question>;
  readonly updateQuestion?: Maybe<Question>;
  readonly addFollowUpQuestion?: Maybe<Question>;
  readonly updateQuestionAnswer?: Maybe<QuestionAnswer>;
  readonly addQuestionCategory?: Maybe<Question>;
  readonly removeQuestionCategory?: Maybe<Question>;
  readonly addQuestionTopic?: Maybe<Question>;
  readonly removeQuestionTopic?: Maybe<Question>;
  readonly addQuestionProject?: Maybe<Question>;
  readonly addAllQuestionProjects?: Maybe<Question>;
  readonly removeQuestionProject?: Maybe<Question>;
  readonly removeAllQuestionProjects?: Maybe<Question>;
  readonly addQuestionPortfolio?: Maybe<Question>;
  readonly addAllQuestionPortfolios?: Maybe<Question>;
  readonly removeQuestionPortfolio?: Maybe<Question>;
  readonly removeAllQuestionPortfolios?: Maybe<Question>;
  readonly moveProject?: Maybe<Project>;
  readonly createProject?: Maybe<Project>;
  readonly updateProject?: Maybe<Project>;
  readonly createRecentlyVisitedPortfolio?: Maybe<FundEntity>;
  readonly createRecentlyVisitedHoldingCompany?: Maybe<FundEntity>;
  readonly createPortfolio?: Maybe<Portfolio>;
  readonly updatePortfolio?: Maybe<Portfolio>;
  readonly deletePortfolio?: Maybe<Portfolio>;
  readonly createDocumentTree?: Maybe<CreatePortfolioDocumentTreeMutation>;
  readonly updateDocumentTree?: Maybe<UpdatePortfolioDocumentTreeMutation>;
  readonly importProjects?: Maybe<ImportProjectsMutation>;
  readonly confirmImportProjects?: Maybe<ConfirmImportProjectsMutation>;
  readonly updateSubpage?: Maybe<UpdateSubpageMutation>;
  readonly createSubpageMedia?: Maybe<CreateSubpageMediaMutation>;
  readonly createUserInvitation?: Maybe<OrgInvitation>;
  readonly updateInvitation?: Maybe<OrgInvitation>;
  readonly updateUserGroups?: Maybe<OrgInvitation>;
  readonly resendUserInvitation?: Maybe<OrgInvitation>;
  readonly deleteUserInvitation?: Maybe<DeletedInvitationResult>;
  readonly createGroup?: Maybe<UserGroup>;
  readonly updateGroup?: Maybe<UserGroup>;
  readonly deleteGroup?: Maybe<DeletedObjectResult>;
  readonly createSummaryJob?: Maybe<SummaryJob>;
  readonly createFolder?: Maybe<Document>;
  readonly createFolders?: Maybe<ReadonlyArray<Maybe<Document>>>;
  readonly createPresignedFiles?: Maybe<ReadonlyArray<Maybe<CreatePresignedFilesResult>>>;
  readonly confirmUploads: ConfirmUploadsResult;
  readonly renameDocument?: Maybe<RenameDocumentMutationResult>;
  readonly moveDocument?: Maybe<ModifiedFolderOutput>;
  readonly removeDocuments?: Maybe<ModifiedFolderOutput>;
  readonly removeDocumentsDryRun?: Maybe<ReadonlyArray<Maybe<Document>>>;
  readonly updateDocumentPermission?: Maybe<ReadonlyArray<Maybe<DocumentPermission>>>;
  readonly downloadFile?: Maybe<FileUrl>;
  readonly submitZipDownload?: Maybe<ZipDownloadOutput>;
  readonly updateChecklistItem?: Maybe<Item>;
  readonly updateNotificationSettings?: Maybe<NotificationSetting>;
  readonly updatePortfolioNotification?: Maybe<PortfolioNotificationSetting>;
  readonly updateHoldCoNotification?: Maybe<HoldCoNotificationSetting>;
  readonly updateUserProfile?: Maybe<User>;
  readonly updateUserPassword?: Maybe<User>;
  readonly updateUserPhoneNumberSendAuth?: Maybe<User>;
  readonly updateUserPhoneNumber?: Maybe<User>;
  readonly updateUserAgreements?: Maybe<User>;
  readonly checkUserPassword?: Maybe<User>;
  readonly uploadAvatar?: Maybe<User>;
  readonly deleteAvatar?: Maybe<User>;
  readonly createSupportTicket?: Maybe<SupportTicketOutput>;
};


export type MutationCreateTimelineArgs = {
  payload: CreateTimelinePayload;
};


export type MutationCreateMilestoneArgs = {
  payload: CreateMilestonePayload;
};


export type MutationUpdateMilestoneArgs = {
  payload: UpdateMilestonePayload;
};


export type MutationDeleteMilestoneArgs = {
  payload: DeleteMilestonePayload;
};


export type MutationReorderMilestoneArgs = {
  payload: ReorderMilestonePayload;
};


export type MutationUploadDataImportArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateQuestionLogArgs = {
  groupIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  name: Scalars['String'];
  restricted?: Maybe<Scalars['Boolean']>;
  subjectId: Scalars['ID'];
  subjectType: QuestionSubjectType;
};


export type MutationUpdateQuestionLogArgs = {
  groupIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  logId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  restricted?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateQuestionArgs = {
  categoryIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  logId: Scalars['ID'];
  portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  projectIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  topicIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateQuestionArgs = {
  body?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isPriority?: Maybe<Scalars['Boolean']>;
  questionId: Scalars['ID'];
};


export type MutationAddFollowUpQuestionArgs = {
  questionId: Scalars['ID'];
};


export type MutationUpdateQuestionAnswerArgs = {
  body: Scalars['String'];
  questionId: Scalars['ID'];
};


export type MutationAddQuestionCategoryArgs = {
  categoryId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationRemoveQuestionCategoryArgs = {
  categoryId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationAddQuestionTopicArgs = {
  questionId: Scalars['ID'];
  topicId: Scalars['ID'];
};


export type MutationRemoveQuestionTopicArgs = {
  questionId: Scalars['ID'];
  topicId: Scalars['ID'];
};


export type MutationAddQuestionProjectArgs = {
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationAddAllQuestionProjectsArgs = {
  questionId: Scalars['ID'];
};


export type MutationRemoveQuestionProjectArgs = {
  projectId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationRemoveAllQuestionProjectsArgs = {
  questionId: Scalars['ID'];
};


export type MutationAddQuestionPortfolioArgs = {
  portfolioId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationAddAllQuestionPortfoliosArgs = {
  questionId: Scalars['ID'];
};


export type MutationRemoveQuestionPortfolioArgs = {
  portfolioId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationRemoveAllQuestionPortfoliosArgs = {
  questionId: Scalars['ID'];
};


export type MutationMoveProjectArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  projectId: Scalars['ID'];
};


export type MutationCreateProjectArgs = {
  acquisitionDate?: Maybe<Scalars['Date']>;
  capacity?: Maybe<Scalars['Int']>;
  capacityAc?: Maybe<Scalars['Int']>;
  commercialOperatingDate?: Maybe<Scalars['Date']>;
  contact?: Maybe<ContactInput>;
  developmentStage?: Maybe<DevelopmentStage>;
  inverters?: Maybe<ReadonlyArray<InverterInput>>;
  isOwned?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationInput>;
  name: Scalars['String'];
  noticeToProceed?: Maybe<Scalars['Date']>;
  offtakers?: Maybe<ReadonlyArray<OfftakerInput>>;
  orgSlug: Scalars['String'];
  panels?: Maybe<ReadonlyArray<PanelInput>>;
  permissionToOperateDate?: Maybe<Scalars['Date']>;
  portfolios?: Maybe<ReadonlyArray<PortfolioInput>>;
  ppaContracts?: Maybe<ReadonlyArray<PpaContractInput>>;
  projectType?: Maybe<ProjectType>;
  status: ProjectStatus;
  system?: Maybe<SystemInput>;
};


export type MutationUpdateProjectArgs = {
  acquisitionDate?: Maybe<Scalars['Date']>;
  capacity?: Maybe<Scalars['Int']>;
  capacityAc?: Maybe<Scalars['Int']>;
  commercialOperatingDate?: Maybe<Scalars['Date']>;
  contact?: Maybe<ContactInput>;
  developmentStage?: Maybe<DevelopmentStage>;
  inverters?: Maybe<ReadonlyArray<InverterInput>>;
  isOwned?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationInput>;
  name?: Maybe<Scalars['String']>;
  noticeToProceed?: Maybe<Scalars['Date']>;
  offtakers?: Maybe<ReadonlyArray<OfftakerInput>>;
  orgSlug: Scalars['String'];
  panels?: Maybe<ReadonlyArray<PanelInput>>;
  permissionToOperateDate?: Maybe<Scalars['Date']>;
  portfolios?: Maybe<ReadonlyArray<PortfolioInput>>;
  ppaContracts?: Maybe<ReadonlyArray<PpaContractInput>>;
  projectId: Scalars['ID'];
  projectType?: Maybe<ProjectType>;
  status?: Maybe<ProjectStatus>;
  system?: Maybe<SystemInput>;
};


export type MutationCreateRecentlyVisitedPortfolioArgs = {
  id: Scalars['ID'];
};


export type MutationCreateRecentlyVisitedHoldingCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePortfolioArgs = {
  dealManagerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  orgSlug: Scalars['String'];
};


export type MutationUpdatePortfolioArgs = {
  dealManagerId?: Maybe<Scalars['ID']>;
  enableProjects?: Maybe<Scalars['Boolean']>;
  enableQAndA?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  orgSlug: Scalars['String'];
  portfolioId: Scalars['ID'];
  removeSystemSize?: Maybe<Scalars['Boolean']>;
  status?: Maybe<WorkflowStatus>;
  systemSize?: Maybe<Scalars['Float']>;
};


export type MutationDeletePortfolioArgs = {
  orgSlug: Scalars['String'];
  portfolioId: Scalars['ID'];
};


export type MutationCreateDocumentTreeArgs = {
  orgSlug: Scalars['String'];
  permissionType: PermissionsSetup;
  portfolioId: Scalars['ID'];
};


export type MutationUpdateDocumentTreeArgs = {
  permissionType: PermissionsSetup;
  treeId: Scalars['ID'];
};


export type MutationImportProjectsArgs = {
  importFile: Scalars['Upload'];
  portfolioId: Scalars['ID'];
};


export type MutationConfirmImportProjectsArgs = {
  importFile: Scalars['Upload'];
  portfolioId: Scalars['ID'];
};


export type MutationUpdateSubpageArgs = {
  description: Scalars['String'];
  subpageId: Scalars['ID'];
};


export type MutationCreateSubpageMediaArgs = {
  image: Scalars['Upload'];
  subjectId: Scalars['ID'];
  subjectType: Scalars['String'];
  subpageId: Scalars['ID'];
};


export type MutationCreateUserInvitationArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  groupId?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  orgSlug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


export type MutationUpdateInvitationArgs = {
  firstName?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  invitationToken: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  orgSlug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserGroupsArgs = {
  groupIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  invitationToken: Scalars['ID'];
  orgSlug: Scalars['String'];
};


export type MutationResendUserInvitationArgs = {
  orgSlug: Scalars['String'];
  token: Scalars['ID'];
};


export type MutationDeleteUserInvitationArgs = {
  orgSlug: Scalars['String'];
  token: Scalars['ID'];
};


export type MutationCreateGroupArgs = {
  name: Scalars['String'];
  orgSlug: Scalars['String'];
  portfolios?: Maybe<ReadonlyArray<PortfolioInput>>;
  roleId: Scalars['ID'];
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orgSlug: Scalars['String'];
  portfolios?: Maybe<ReadonlyArray<PortfolioInput>>;
  roleId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['ID'];
  orgSlug: Scalars['String'];
};


export type MutationCreateSummaryJobArgs = {
  documentIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  jobType: SummaryJobType;
  treeId: Scalars['ID'];
};


export type MutationCreateFolderArgs = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  treeId: Scalars['ID'];
};


export type MutationCreateFoldersArgs = {
  folderPaths: ReadonlyArray<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  treeId: Scalars['ID'];
};


export type MutationCreatePresignedFilesArgs = {
  files: ReadonlyArray<FileInput>;
  treeId: Scalars['ID'];
};


export type MutationConfirmUploadsArgs = {
  treeId: Scalars['ID'];
  uploads: ReadonlyArray<ConfirmUploadInput>;
};


export type MutationRenameDocumentArgs = {
  documentId: Scalars['ID'];
  name: Scalars['String'];
  treeId: Scalars['ID'];
};


export type MutationMoveDocumentArgs = {
  destinationParentId: Scalars['ID'];
  sourceDocumentIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  treeId: Scalars['ID'];
};


export type MutationRemoveDocumentsArgs = {
  documentIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  treeId: Scalars['ID'];
};


export type MutationRemoveDocumentsDryRunArgs = {
  documentIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  treeId: Scalars['ID'];
};


export type MutationUpdateDocumentPermissionArgs = {
  documentPermissionId: Scalars['ID'];
  permissible: Scalars['Boolean'];
  treeId: Scalars['ID'];
};


export type MutationDownloadFileArgs = {
  documentId: Scalars['ID'];
  treeId: Scalars['ID'];
};


export type MutationSubmitZipDownloadArgs = {
  documentIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  treeId: Scalars['ID'];
};


export type MutationUpdateChecklistItemArgs = {
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  status?: Maybe<ChecklistItemStatus>;
  userId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateNotificationSettingsArgs = {
  enabled: Scalars['Boolean'];
  orgSlug: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationUpdatePortfolioNotificationArgs = {
  enabled: Scalars['Boolean'];
  portfolioId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateHoldCoNotificationArgs = {
  enabled: Scalars['Boolean'];
  holdingCompanyId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateUserProfileArgs = {
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  title: Scalars['String'];
};


export type MutationUpdateUserPasswordArgs = {
  id: Scalars['ID'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationUpdateUserPhoneNumberSendAuthArgs = {
  countryCode: Scalars['String'];
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
};


export type MutationUpdateUserPhoneNumberArgs = {
  authCode: Scalars['String'];
  countryCode: Scalars['String'];
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
};


export type MutationUpdateUserAgreementsArgs = {
  acceptedAgreements: Scalars['Boolean'];
  emailAgreement?: Maybe<Scalars['Boolean']>;
};


export type MutationCheckUserPasswordArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
};


export type MutationUploadAvatarArgs = {
  file: Scalars['Upload'];
  userId: Scalars['ID'];
};


export type MutationDeleteAvatarArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateSupportTicketArgs = {
  description: Scalars['String'];
  issue: Scalars['String'];
};

export type NetYield = {
  readonly __typename: 'NetYield';
  readonly id: Scalars['ID'];
  readonly year: Scalars['Int'];
  readonly actualEnergy?: Maybe<Scalars['Float']>;
  readonly p50: Scalars['Float'];
  readonly p90: Scalars['Float'];
};

export type NotificationSetting = {
  readonly __typename: 'NotificationSetting';
  readonly id: Scalars['ID'];
  readonly enabled?: Maybe<Scalars['Boolean']>;
};

export type OAndMPartner = {
  readonly __typename: 'OAndMPartner';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly projectCount?: Maybe<Scalars['Int']>;
  readonly totalCapacity?: Maybe<Scalars['Int']>;
};

export type Offtaker = {
  readonly __typename: 'Offtaker';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly quality?: Maybe<RatingType>;
  readonly note: Scalars['String'];
};

export type OfftakerInput = {
  readonly id?: Maybe<Scalars['ID']>;
};

export type OperatingStats = {
  readonly __typename: 'OperatingStats';
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly nextReportDate?: Maybe<Scalars['Date']>;
  readonly actualExpenses?: Maybe<Scalars['Float']>;
  readonly actualProduction?: Maybe<Scalars['Float']>;
  readonly actualRevenue?: Maybe<Scalars['Float']>;
  readonly forecastExpenses?: Maybe<Scalars['Float']>;
  readonly forecastProduction?: Maybe<Scalars['Float']>;
  readonly forecastRevenue?: Maybe<Scalars['Float']>;
};

export type OrgFeatureFlags = {
  readonly __typename: 'OrgFeatureFlags';
  readonly documentLabels?: Maybe<Scalars['Boolean']>;
  readonly copilot?: Maybe<Scalars['Boolean']>;
};

export type OrgInvitation = {
  readonly __typename: 'OrgInvitation';
  readonly token: Scalars['UUID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly title: Scalars['String'];
  readonly email: Scalars['String'];
  readonly existingUser?: Maybe<User>;
  readonly accepted: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly id?: Maybe<Scalars['ID']>;
  readonly groupId?: Maybe<Scalars['ID']>;
  readonly groupIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
};


export type OrgInvitationExistingUserArgs = {
  orgSlug: Scalars['String'];
};


export type OrgInvitationGroupIdsArgs = {
  orgSlug: Scalars['String'];
};

export type Organization = {
  readonly __typename: 'Organization';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly slug: Scalars['String'];
  readonly company?: Maybe<Company>;
  readonly permissions: ReadonlyArray<PermissionType>;
  readonly featureFlags: OrgFeatureFlags;
};

export type PpaContract = {
  readonly __typename: 'PPAContract';
  readonly id: Scalars['ID'];
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly term: Scalars['Int'];
  readonly rate: Scalars['Decimal'];
  readonly escalator: Scalars['Decimal'];
};

export type PpaContractInput = {
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly term?: Maybe<Scalars['Int']>;
  readonly rate?: Maybe<Scalars['Decimal']>;
  readonly escalator?: Maybe<Scalars['Decimal']>;
};

export type PaginatedCompanyAggs = {
  readonly __typename: 'PaginatedCompanyAggs';
  readonly companies?: Maybe<ReadonlyArray<CompanyAggs>>;
  readonly companyCount?: Maybe<Scalars['Int']>;
  readonly count?: Maybe<Scalars['Int']>;
  readonly maxUser?: Maybe<Scalars['Int']>;
  readonly maxSession?: Maybe<Scalars['Int']>;
  readonly maxDownload?: Maybe<Scalars['Int']>;
  readonly maxView?: Maybe<Scalars['Int']>;
};

export type PaginatedDocumentActivity = {
  readonly __typename: 'PaginatedDocumentActivity';
  readonly activity?: Maybe<ReadonlyArray<DocumentActivity>>;
  readonly count?: Maybe<Scalars['Int']>;
};

export type PaginatedDocumentAggs = {
  readonly __typename: 'PaginatedDocumentAggs';
  readonly id: Scalars['ID'];
  readonly documents?: Maybe<ReadonlyArray<DocumentAggs>>;
  readonly count?: Maybe<Scalars['Int']>;
  readonly maxDownload?: Maybe<Scalars['Int']>;
  readonly maxView?: Maybe<Scalars['Int']>;
};

export type PaginatedGroups = {
  readonly __typename: 'PaginatedGroups';
  readonly groups?: Maybe<ReadonlyArray<UserGroup>>;
  readonly count?: Maybe<Scalars['Int']>;
};

export type PaginatedOrgInvitation = {
  readonly __typename: 'PaginatedOrgInvitation';
  readonly invitations?: Maybe<ReadonlyArray<OrgInvitation>>;
  readonly count?: Maybe<Scalars['Int']>;
};

export type PaginatedUserAggs = {
  readonly __typename: 'PaginatedUserAggs';
  readonly users?: Maybe<ReadonlyArray<UserAggs>>;
  readonly count?: Maybe<Scalars['Int']>;
  readonly maxSession?: Maybe<Scalars['Int']>;
  readonly maxDownload?: Maybe<Scalars['Int']>;
  readonly maxView?: Maybe<Scalars['Int']>;
};

export type PaginatedUserGroupMemberships = {
  readonly __typename: 'PaginatedUserGroupMemberships';
  readonly userGroupMemberships?: Maybe<ReadonlyArray<UserGroupMembership>>;
  readonly count?: Maybe<Scalars['Int']>;
};

export type PanelInput = {
  readonly modelId?: Maybe<Scalars['ID']>;
  readonly count?: Maybe<Scalars['Int']>;
};

export type PanelManufacturer = Manufacturer & {
  readonly __typename: 'PanelManufacturer';
  readonly id: Scalars['ID'];
  readonly slug: Scalars['String'];
  readonly name: Scalars['String'];
};

export type PanelModel = ManufacturerModel & {
  readonly __typename: 'PanelModel';
  readonly id: Scalars['ID'];
  readonly slug: Scalars['String'];
  readonly modelNumber: Scalars['String'];
  readonly manufacturer: Manufacturer;
};

export type Panels = {
  readonly __typename: 'Panels';
  readonly id: Scalars['ID'];
  readonly count?: Maybe<Scalars['Int']>;
  readonly panelModelId?: Maybe<Scalars['ID']>;
  readonly modelId?: Maybe<Scalars['String']>;
};

export type Permission = {
  readonly __typename: 'Permission';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly codename: Scalars['String'];
};

export enum PermissionType {
  AccessLogView = 'ACCESS_LOG_VIEW',
  ChecklistsAdmin = 'CHECKLISTS_ADMIN',
  DealroomView = 'DEALROOM_VIEW',
  DealroomAdmin = 'DEALROOM_ADMIN',
  DocumentsAdmin = 'DOCUMENTS_ADMIN',
  DocumentsEdit = 'DOCUMENTS_EDIT',
  DocumentsDelete = 'DOCUMENTS_DELETE',
  DocumentsHistoryView = 'DOCUMENTS_HISTORY_VIEW',
  DocumentsView = 'DOCUMENTS_VIEW',
  OAndMAdmin = 'O_AND_M_ADMIN',
  OAndMView = 'O_AND_M_VIEW',
  OverviewView = 'OVERVIEW_VIEW',
  OrgAdmin = 'ORG_ADMIN',
  ProjectsView = 'PROJECTS_VIEW',
  ProjectsAdmin = 'PROJECTS_ADMIN',
  QuestionLogAdmin = 'QUESTION_LOG_ADMIN',
  QuestionAnswersEdit = 'QUESTION_ANSWERS_EDIT',
  QuestionAnswersView = 'QUESTION_ANSWERS_VIEW',
  QuestionsEdit = 'QUESTIONS_EDIT',
  QuestionsView = 'QUESTIONS_VIEW',
  ReportsAdmin = 'REPORTS_ADMIN',
  ReportsEdit = 'REPORTS_EDIT',
  ReportsHistoryView = 'REPORTS_HISTORY_VIEW',
  ReportsView = 'REPORTS_VIEW',
  UsersAdmin = 'USERS_ADMIN'
}

export enum PermissionsSetup {
  Role = 'ROLE',
  Group = 'GROUP'
}

export type Portfolio = FundEntity & {
  readonly __typename: 'Portfolio';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly slug: Scalars['String'];
  readonly systemSize?: Maybe<Scalars['Float']>;
  readonly averageOfftakerQuality?: Maybe<Scalars['String']>;
  readonly createdDate: Scalars['DateTime'];
  readonly enableProjects: Scalars['Boolean'];
  readonly enableQAndA: Scalars['Boolean'];
  readonly coverImage?: Maybe<Scalars['String']>;
  readonly org: Organization;
  readonly status?: Maybe<WorkflowStatus>;
  readonly systemSizeAc?: Maybe<Scalars['Float']>;
  readonly systemSizeEss?: Maybe<Scalars['Float']>;
  readonly dealManager?: Maybe<User>;
  readonly hasSubpages?: Maybe<Scalars['Boolean']>;
  readonly subpages?: Maybe<ReadonlyArray<SubpageInterface>>;
  readonly subpageMedia?: Maybe<ReadonlyArray<SubpageMediaInterface>>;
  readonly userGroups?: Maybe<ReadonlyArray<UserGroup>>;
  readonly netYields?: Maybe<ReadonlyArray<NetYield>>;
  readonly documentsTree?: Maybe<DocumentTree>;
  readonly reportsTree?: Maybe<DocumentTree>;
  readonly operatingStats?: Maybe<OperatingStats>;
  readonly projectsStats?: Maybe<ProjectsStats>;
  readonly qAndALogs?: Maybe<ReadonlyArray<QuestionLog>>;
  readonly projects?: Maybe<ReadonlyArray<Project>>;
  readonly buildStatus?: Maybe<Scalars['String']>;
  readonly projectCount?: Maybe<Scalars['Int']>;
  readonly remainingPpaTerm?: Maybe<Scalars['Float']>;
  readonly hasChecklist?: Maybe<Scalars['Boolean']>;
  readonly enableReports?: Maybe<Scalars['Boolean']>;
  readonly headerEnabled?: Maybe<Scalars['Boolean']>;
};


export type PortfolioProjectsArgs = {
  search?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['String']>;
};

export type PortfolioInput = {
  readonly id?: Maybe<Scalars['ID']>;
};

export type PortfolioNotificationSetting = {
  readonly __typename: 'PortfolioNotificationSetting';
  readonly id: Scalars['ID'];
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly name?: Maybe<Scalars['String']>;
};

export enum PortfolioStatus {
  Draft = 'DRAFT',
  Acquired = 'ACQUIRED',
  Diligence = 'DILIGENCE',
  Sold = 'SOLD',
  Market = 'MARKET',
  Deleted = 'DELETED'
}

export type Project = {
  readonly __typename: 'Project';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly commercialOperatingDate?: Maybe<Scalars['Date']>;
  readonly permissionToOperateDate?: Maybe<Scalars['Date']>;
  readonly noticeToProceed?: Maybe<Scalars['Date']>;
  readonly acquisitionDate?: Maybe<Scalars['Date']>;
  readonly status?: Maybe<ProjectStatus>;
  readonly developmentStage?: Maybe<DevelopmentStage>;
  readonly isOwned?: Maybe<Scalars['Boolean']>;
  readonly capacity?: Maybe<Scalars['Float']>;
  readonly capacityAc?: Maybe<Scalars['Float']>;
  readonly spatial?: Maybe<ProjectSpatial>;
  readonly geoJsonUrl?: Maybe<Scalars['String']>;
  readonly location: ProjectLocation;
  readonly system: System;
  readonly utility?: Maybe<Utility>;
  readonly contact: ProjectContact;
  readonly type?: Maybe<ProjectType>;
  readonly cod?: Maybe<Scalars['DateTime']>;
  readonly ntp?: Maybe<Scalars['DateTime']>;
  readonly systemSizeDc?: Maybe<Scalars['Int']>;
  readonly systemSizeAc?: Maybe<Scalars['Int']>;
  readonly ppaLength?: Maybe<Scalars['Int']>;
  readonly ppaRemainingAvg?: Maybe<Scalars['Int']>;
  readonly panelCount?: Maybe<Scalars['Int']>;
  readonly inverterCount?: Maybe<Scalars['Int']>;
  readonly offtakerName?: Maybe<Scalars['String']>;
  readonly offtakerCredit?: Maybe<Scalars['String']>;
  readonly offtakers?: Maybe<ReadonlyArray<Offtaker>>;
  readonly ppaContracts?: Maybe<ReadonlyArray<PpaContract>>;
  readonly panels?: Maybe<ReadonlyArray<Panels>>;
  readonly inverters?: Maybe<ReadonlyArray<Inverters>>;
  readonly portfolios?: Maybe<ReadonlyArray<Portfolio>>;
  readonly moduleManufacturer?: Maybe<Scalars['String']>;
  readonly inverterManufacturer?: Maybe<Scalars['String']>;
  readonly acquiredPortfolio?: Maybe<Scalars['String']>;
  readonly withinOneCount?: Maybe<Scalars['Int']>;
  readonly withinTenCount?: Maybe<Scalars['Int']>;
  readonly withinOneSumCapacityAc?: Maybe<Scalars['Int']>;
  readonly withinTenSumCapacityAc?: Maybe<Scalars['Int']>;
  readonly withinOne?: Maybe<ReadonlyArray<ProjectDict>>;
  readonly withinTen?: Maybe<ReadonlyArray<ProjectDict>>;
};


export type ProjectWithinOneCountArgs = {
  portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  portfolioStatuses?: Maybe<ReadonlyArray<Maybe<PortfolioStatus>>>;
};


export type ProjectWithinTenCountArgs = {
  portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  portfolioStatuses?: Maybe<ReadonlyArray<Maybe<PortfolioStatus>>>;
};


export type ProjectWithinOneSumCapacityAcArgs = {
  portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  portfolioStatuses?: Maybe<ReadonlyArray<Maybe<PortfolioStatus>>>;
};


export type ProjectWithinTenSumCapacityAcArgs = {
  portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  portfolioStatuses?: Maybe<ReadonlyArray<Maybe<PortfolioStatus>>>;
};


export type ProjectWithinOneArgs = {
  portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  portfolioStatuses?: Maybe<ReadonlyArray<Maybe<PortfolioStatus>>>;
};


export type ProjectWithinTenArgs = {
  portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  portfolioStatuses?: Maybe<ReadonlyArray<Maybe<PortfolioStatus>>>;
};

export type ProjectContact = {
  readonly __typename: 'ProjectContact';
  readonly id: Scalars['ID'];
  readonly phoneNumber: Scalars['String'];
  readonly balancingAuthorityAreaName: Scalars['String'];
};

export type ProjectCrossLink = {
  readonly __typename: 'ProjectCrossLink';
  readonly portfolio?: Maybe<Portfolio>;
  readonly document?: Maybe<Document>;
};

export type ProjectDict = {
  readonly __typename: 'ProjectDict';
  readonly id?: Maybe<Scalars['ID']>;
  readonly name?: Maybe<Scalars['String']>;
};

export type ProjectLocation = {
  readonly __typename: 'ProjectLocation';
  readonly id: Scalars['ID'];
  readonly address: Scalars['String'];
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly zipCode: Scalars['String'];
  readonly latitude?: Maybe<Scalars['Float']>;
  readonly longitude?: Maybe<Scalars['Float']>;
  readonly elevation?: Maybe<Scalars['Int']>;
};

export type ProjectSpatial = {
  readonly __typename: 'ProjectSpatial';
  readonly id: Scalars['ID'];
  readonly geoJsonFile: Scalars['String'];
  readonly features?: Maybe<ReadonlyArray<SpatialFeature>>;
  readonly geoJsonUrl: Scalars['String'];
};

export enum ProjectStatus {
  Operating = 'OPERATING',
  NewBuild = 'NEW_BUILD'
}

export enum ProjectType {
  Solar = 'SOLAR',
  Storage = 'STORAGE',
  SolarPlusStorage = 'SOLAR_PLUS_STORAGE'
}

export type ProjectsCollection = {
  readonly __typename: 'ProjectsCollection';
  readonly aggregations?: Maybe<ProjectsCollectionAggregations>;
  readonly projects?: Maybe<ReadonlyArray<Project>>;
};

export type ProjectsCollectionAggregations = {
  readonly __typename: 'ProjectsCollectionAggregations';
  readonly count?: Maybe<Scalars['Int']>;
  readonly totalCapacity?: Maybe<Scalars['Float']>;
  readonly maxCapacity?: Maybe<Scalars['Float']>;
  readonly minCapacity?: Maybe<Scalars['Float']>;
};

export type ProjectsStats = {
  readonly __typename: 'ProjectsStats';
  readonly count: Scalars['Int'];
  readonly totalCapacity: Scalars['Int'];
  readonly maxCapacity: Scalars['Int'];
  readonly minCapacity: Scalars['Int'];
  readonly emissionsOffset: Scalars['Int'];
};

export type Query = {
  readonly __typename: 'Query';
  readonly timeline?: Maybe<Timeline>;
  readonly dataImports?: Maybe<ReadonlyArray<SalesforceDataImport>>;
  readonly duplicateReports?: Maybe<ReadonlyArray<SalesforceDuplicateReport>>;
  readonly questions?: Maybe<ReadonlyArray<Question>>;
  readonly questionCategories?: Maybe<ReadonlyArray<QuestionCategory>>;
  readonly questionTopics?: Maybe<ReadonlyArray<QuestionTopic>>;
  readonly panelManufacturers?: Maybe<ReadonlyArray<PanelManufacturer>>;
  readonly panelModels?: Maybe<ReadonlyArray<PanelModel>>;
  readonly inverterManufacturers?: Maybe<ReadonlyArray<InverterManufacturer>>;
  readonly inverterModels?: Maybe<ReadonlyArray<InverterModel>>;
  readonly offtakers?: Maybe<ReadonlyArray<Offtaker>>;
  readonly projects?: Maybe<ProjectsCollection>;
  readonly project?: Maybe<Project>;
  readonly projectCrossLinks?: Maybe<ReadonlyArray<Maybe<ProjectCrossLink>>>;
  readonly acquiredProjectsStats?: Maybe<ProjectsStats>;
  readonly reportingProjectsStats?: Maybe<ProjectsStats>;
  readonly reportingOperatingStats?: Maybe<OperatingStats>;
  readonly reportingHoldingCompanies?: Maybe<ReadonlyArray<Maybe<HoldingCompany>>>;
  readonly recentlyVisited?: Maybe<ReadonlyArray<FundEntity>>;
  readonly fundEntities?: Maybe<FundEntityCollection>;
  readonly portfolio?: Maybe<Portfolio>;
  readonly portfolios?: Maybe<ReadonlyArray<Portfolio>>;
  readonly holdingCompany?: Maybe<HoldingCompany>;
  readonly exportProjectImportTemplate?: Maybe<ExportProjectImportTemplate>;
  readonly subpageMedia?: Maybe<ReadonlyArray<GenericSubpageMedia>>;
  readonly organizations?: Maybe<Organization>;
  readonly orgUserGroups?: Maybe<ReadonlyArray<UserGroup>>;
  readonly orgPortfolios?: Maybe<ReadonlyArray<Portfolio>>;
  readonly orgUsers?: Maybe<ReadonlyArray<User>>;
  readonly role?: Maybe<Role>;
  readonly roles?: Maybe<ReadonlyArray<Role>>;
  readonly invitation: OrgInvitation;
  readonly invitationPages: PaginatedOrgInvitation;
  readonly groupPages: PaginatedGroups;
  readonly invitationsData?: Maybe<InvitationsData>;
  readonly oAndMPartners?: Maybe<ReadonlyArray<Maybe<OAndMPartner>>>;
  readonly oAndMTree?: Maybe<DocumentTree>;
  readonly documentTree?: Maybe<DocumentTree>;
  readonly companyAggs: PaginatedCompanyAggs;
  readonly userAggs: PaginatedUserAggs;
  readonly documentAggs: PaginatedDocumentAggs;
  readonly document?: Maybe<Document>;
  readonly documents?: Maybe<ReadonlyArray<Document>>;
  readonly userGroups?: Maybe<ReadonlyArray<UserGroup>>;
  readonly writeHistory: PaginatedDocumentActivity;
  readonly fileUrl: FileUrl;
  readonly filePreviewUrl: FileUrl;
  readonly checkRemoveDocuments?: Maybe<ReadonlyArray<Document>>;
  readonly summaryJobs?: Maybe<ReadonlyArray<SummaryJob>>;
  readonly summaryExtractItems?: Maybe<ReadonlyArray<SummaryExtractItem>>;
  readonly checklist?: Maybe<Checklist>;
  readonly checklistItem?: Maybe<Item>;
  readonly checklistItems?: Maybe<ReadonlyArray<Item>>;
  readonly activeChecklists?: Maybe<ReadonlyArray<Checklist>>;
  readonly notificationSettings?: Maybe<NotificationSetting>;
  readonly portfolioNotifications?: Maybe<ReadonlyArray<PortfolioNotificationSetting>>;
  readonly holdCoNotifications?: Maybe<ReadonlyArray<HoldCoNotificationSetting>>;
  readonly user?: Maybe<User>;
  readonly portfolioUsers?: Maybe<ReadonlyArray<User>>;
  readonly viewer?: Maybe<User>;
  readonly userGroupMembers: PaginatedUserGroupMemberships;
};


export type QueryTimelineArgs = {
  timelineId: Scalars['ID'];
};


export type QueryQuestionsArgs = {
  logId: Scalars['ID'];
};


export type QueryQuestionCategoriesArgs = {
  logId: Scalars['ID'];
};


export type QueryQuestionTopicsArgs = {
  logId: Scalars['ID'];
};


export type QueryOfftakersArgs = {
  orgSlug: Scalars['String'];
};


export type QueryProjectsArgs = {
  orgSlug: Scalars['String'];
  portfolioId?: Maybe<Scalars['ID']>;
  portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  isOwned?: Maybe<Scalars['Boolean']>;
  workflowStatus?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['String']>;
};


export type QueryProjectArgs = {
  projectId: Scalars['ID'];
};


export type QueryProjectCrossLinksArgs = {
  projectId: Scalars['ID'];
};


export type QueryAcquiredProjectsStatsArgs = {
  orgSlug: Scalars['String'];
};


export type QueryReportingProjectsStatsArgs = {
  orgSlug: Scalars['String'];
};


export type QueryReportingOperatingStatsArgs = {
  orgSlug: Scalars['String'];
};


export type QueryReportingHoldingCompaniesArgs = {
  orgSlug: Scalars['String'];
};


export type QueryRecentlyVisitedArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFundEntitiesArgs = {
  category: FundEntityCategory;
  orgSlug?: Maybe<Scalars['String']>;
};


export type QueryPortfolioArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryPortfoliosArgs = {
  orgSlug?: Maybe<Scalars['String']>;
  holdingCompanyId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  workflowStatus?: Maybe<Scalars['Int']>;
  workflowStatuses?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
};


export type QueryHoldingCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryExportProjectImportTemplateArgs = {
  portfolioId: Scalars['ID'];
};


export type QuerySubpageMediaArgs = {
  subjectId: Scalars['ID'];
  subjectType: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  userId: Scalars['ID'];
};


export type QueryOrgUserGroupsArgs = {
  orgSlug: Scalars['String'];
};


export type QueryOrgPortfoliosArgs = {
  orgSlug: Scalars['String'];
  workflowStatuses?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  excludeWorkflowStatuses?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
};


export type QueryOrgUsersArgs = {
  orgSlug: Scalars['String'];
};


export type QueryRoleArgs = {
  orgSlug: Scalars['String'];
  roleId: Scalars['ID'];
};


export type QueryRolesArgs = {
  orgSlug: Scalars['String'];
};


export type QueryInvitationArgs = {
  orgSlug: Scalars['String'];
  token: Scalars['ID'];
};


export type QueryInvitationPagesArgs = {
  orgSlug: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  accepted?: Maybe<Scalars['Boolean']>;
};


export type QueryGroupPagesArgs = {
  orgSlug: Scalars['String'];
  search?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryInvitationsDataArgs = {
  orgSlug: Scalars['String'];
};


export type QueryOAndMPartnersArgs = {
  orgSlug: Scalars['String'];
};


export type QueryOAndMTreeArgs = {
  orgSlug: Scalars['String'];
};


export type QueryDocumentTreeArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyAggsArgs = {
  treeId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
};


export type QueryUserAggsArgs = {
  treeId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
};


export type QueryDocumentAggsArgs = {
  treeId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
};


export type QueryDocumentArgs = {
  treeId: Scalars['ID'];
  documentId: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  treeId: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  labels?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};


export type QueryUserGroupsArgs = {
  treeId: Scalars['ID'];
};


export type QueryWriteHistoryArgs = {
  treeId: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  ancestorIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  activityTypes?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  fileTypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  userGroupIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryFileUrlArgs = {
  treeId: Scalars['ID'];
  documentId: Scalars['ID'];
};


export type QueryFilePreviewUrlArgs = {
  treeId: Scalars['ID'];
  documentId: Scalars['ID'];
};


export type QueryCheckRemoveDocumentsArgs = {
  treeId: Scalars['ID'];
  documentIds: ReadonlyArray<Maybe<Scalars['ID']>>;
};


export type QuerySummaryJobsArgs = {
  treeId: Scalars['ID'];
};


export type QueryChecklistArgs = {
  id: Scalars['ID'];
};


export type QueryChecklistItemArgs = {
  id: Scalars['ID'];
};


export type QueryChecklistItemsArgs = {
  checklistId: Scalars['ID'];
};


export type QueryActiveChecklistsArgs = {
  portfolioId: Scalars['ID'];
};


export type QueryNotificationSettingsArgs = {
  userId: Scalars['ID'];
  orgSlug: Scalars['String'];
};


export type QueryPortfolioNotificationsArgs = {
  userId: Scalars['ID'];
  orgSlug: Scalars['String'];
};


export type QueryHoldCoNotificationsArgs = {
  userId: Scalars['ID'];
  orgSlug: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryPortfolioUsersArgs = {
  portfolioId: Scalars['ID'];
};


export type QueryUserGroupMembersArgs = {
  fundEntityId: Scalars['ID'];
  fundEntityType: FundEntityType;
  search?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type Question = {
  readonly __typename: 'Question';
  readonly id: Scalars['ID'];
  readonly body: Scalars['String'];
  readonly number: Scalars['String'];
  readonly isClosed: Scalars['Boolean'];
  readonly isPriority: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly modifiedAt: Scalars['DateTime'];
  readonly answer: QuestionAnswer;
  readonly followUps: ReadonlyArray<Question>;
  readonly categories: ReadonlyArray<QuestionCategory>;
  readonly topics: ReadonlyArray<QuestionTopic>;
  readonly projects: ReadonlyArray<Project>;
  readonly portfolios: ReadonlyArray<Portfolio>;
  readonly parentId?: Maybe<Scalars['ID']>;
  readonly firstViewedAt: Scalars['DateTime'];
  readonly viewedAt: Scalars['DateTime'];
};

export type QuestionAnswer = {
  readonly __typename: 'QuestionAnswer';
  readonly id: Scalars['ID'];
  readonly body: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly modifiedAt: Scalars['DateTime'];
};

export type QuestionCategory = {
  readonly __typename: 'QuestionCategory';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type QuestionLog = {
  readonly __typename: 'QuestionLog';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly restricted: Scalars['Boolean'];
  readonly groups?: Maybe<ReadonlyArray<UserGroup>>;
};

export enum QuestionSubjectType {
  Portfolio = 'PORTFOLIO',
  HoldingCompany = 'HOLDING_COMPANY'
}

export type QuestionTopic = {
  readonly __typename: 'QuestionTopic';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export enum RatingType {
  MoodysCreditRatingAaa = 'MOODYS_CREDIT_RATING_AAA',
  MoodysCreditRatingAa1 = 'MOODYS_CREDIT_RATING_AA1',
  MoodysCreditRatingAa2 = 'MOODYS_CREDIT_RATING_AA2',
  MoodysCreditRatingAa3 = 'MOODYS_CREDIT_RATING_AA3',
  MoodysCreditRatingA1 = 'MOODYS_CREDIT_RATING_A1',
  MoodysCreditRatingA2 = 'MOODYS_CREDIT_RATING_A2',
  MoodysCreditRatingA3 = 'MOODYS_CREDIT_RATING_A3',
  MoodysCreditRatingBaa1 = 'MOODYS_CREDIT_RATING_BAA1',
  MoodysCreditRatingBaa2 = 'MOODYS_CREDIT_RATING_BAA2',
  MoodysCreditRatingBaa3 = 'MOODYS_CREDIT_RATING_BAA3',
  MoodysCreditRatingBa1 = 'MOODYS_CREDIT_RATING_BA1',
  MoodysCreditRatingBa2 = 'MOODYS_CREDIT_RATING_BA2',
  MoodysCreditRatingBa3 = 'MOODYS_CREDIT_RATING_BA3',
  MoodysCreditRatingB1 = 'MOODYS_CREDIT_RATING_B1',
  MoodysCreditRatingB2 = 'MOODYS_CREDIT_RATING_B2',
  MoodysCreditRatingB3 = 'MOODYS_CREDIT_RATING_B3',
  MoodysCreditRatingCaa1 = 'MOODYS_CREDIT_RATING_CAA1',
  CreditRatingAaa = 'CREDIT_RATING_AAA',
  CreditRatingAa1 = 'CREDIT_RATING_AA1',
  CreditRatingAa2 = 'CREDIT_RATING_AA2',
  CreditRatingAa3 = 'CREDIT_RATING_AA3',
  CreditRatingA1 = 'CREDIT_RATING_A1',
  CreditRatingA2 = 'CREDIT_RATING_A2',
  CreditRatingA3 = 'CREDIT_RATING_A3',
  CreditRatingBaa1 = 'CREDIT_RATING_BAA1',
  CreditRatingBaa2 = 'CREDIT_RATING_BAA2',
  CreditRatingBaa3 = 'CREDIT_RATING_BAA3',
  CreditRatingBa1 = 'CREDIT_RATING_BA1',
  CreditRatingBa2 = 'CREDIT_RATING_BA2',
  CreditRatingBa3 = 'CREDIT_RATING_BA3',
  CreditRatingB1 = 'CREDIT_RATING_B1',
  CreditRatingB2 = 'CREDIT_RATING_B2',
  CreditRatingB3 = 'CREDIT_RATING_B3',
  CreditRatingCaa1 = 'CREDIT_RATING_CAA1',
  CreditNA = 'CREDIT_N_A',
  CreditUnknown = 'CREDIT_UNKNOWN',
  CreditEndowment = 'CREDIT_ENDOWMENT',
  CreditInvestmentGrade = 'CREDIT_INVESTMENT_GRADE',
  CreditNonInvestmentGrade = 'CREDIT_NON_INVESTMENT_GRADE'
}

export type RenameDocumentMutationResult = {
  readonly __typename: 'RenameDocumentMutationResult';
  readonly document?: Maybe<Document>;
  readonly error?: Maybe<Scalars['String']>;
};

export type ReorderMilestoneMutation = {
  readonly __typename: 'ReorderMilestoneMutation';
  readonly timeline?: Maybe<Timeline>;
};

export type ReorderMilestonePayload = {
  readonly milestoneId: Scalars['ID'];
  readonly up: Scalars['Boolean'];
};

export type Role = {
  readonly __typename: 'Role';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly permissions?: Maybe<ReadonlyArray<Permission>>;
};

export type SalesforceDataImport = {
  readonly __typename: 'SalesforceDataImport';
  readonly id: Scalars['UUID'];
  readonly file: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly status: Scalars['String'];
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileUrl?: Maybe<Scalars['String']>;
  readonly vendor?: Maybe<Scalars['String']>;
  readonly importedBy?: Maybe<Scalars['String']>;
};

export type SalesforceDuplicateReport = {
  readonly __typename: 'SalesforceDuplicateReport';
  readonly id: Scalars['UUID'];
  readonly file: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly status?: Maybe<Scalars['String']>;
  readonly fileName?: Maybe<Scalars['String']>;
  readonly fileUrl?: Maybe<Scalars['String']>;
  readonly createdBy?: Maybe<Scalars['String']>;
};

export type SpatialFeature = {
  readonly __typename: 'SpatialFeature';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
  readonly stroke?: Maybe<Scalars['String']>;
  readonly strokeOpacity?: Maybe<Scalars['Float']>;
  readonly strokeWidth?: Maybe<Scalars['Float']>;
  readonly fill?: Maybe<Scalars['String']>;
  readonly fillOpacity?: Maybe<Scalars['Float']>;
  readonly geoJsonUrl: Scalars['String'];
};

export type Subpage = SubpageInterface & {
  readonly __typename: 'Subpage';
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly description: Scalars['String'];
  readonly order: Scalars['String'];
  readonly slug: Scalars['String'];
};

export type SubpageInterface = {
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly slug: Scalars['String'];
  readonly description: Scalars['String'];
  readonly order: Scalars['String'];
};

export type SubpageMediaInterface = {
  readonly id: Scalars['ID'];
  readonly url: Scalars['String'];
};

export type SummaryExtractItem = {
  readonly __typename: 'SummaryExtractItem';
  readonly id: Scalars['ID'];
  readonly jobType?: Maybe<SummaryJobType>;
  readonly displayName: Scalars['String'];
};

export type SummaryJob = {
  readonly __typename: 'SummaryJob';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly complete: Scalars['Boolean'];
  readonly outputFileUrl?: Maybe<Scalars['String']>;
};

export enum SummaryJobType {
  PpaAnalysis = 'PPA_ANALYSIS',
  Insurance = 'INSURANCE',
  DamInspection = 'DAM_INSPECTION'
}

export type SupportTicketOutput = {
  readonly __typename: 'SupportTicketOutput';
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type System = {
  readonly __typename: 'System';
  readonly id: Scalars['ID'];
  readonly system: Scalars['String'];
  readonly solarCapacityDc?: Maybe<Scalars['Float']>;
  readonly solarCapacityAc?: Maybe<Scalars['Float']>;
  readonly bessKw?: Maybe<Scalars['Float']>;
  readonly bessKwh?: Maybe<Scalars['Float']>;
  readonly panelWarranty?: Maybe<Scalars['Int']>;
  readonly inverterWarranty?: Maybe<Scalars['Int']>;
  readonly contractorWarranty?: Maybe<Scalars['Int']>;
  readonly installation?: Maybe<InstallationType>;
  readonly mounting?: Maybe<MountingType>;
};

export type SystemInput = {
  readonly bessKw?: Maybe<Scalars['Float']>;
  readonly bessKwh?: Maybe<Scalars['Float']>;
  readonly panelWarranty?: Maybe<Scalars['Int']>;
  readonly inverterWarranty?: Maybe<Scalars['Int']>;
  readonly contractorWarranty?: Maybe<Scalars['Int']>;
  readonly installation?: Maybe<InstallationType>;
  readonly mounting?: Maybe<MountingType>;
};

export type Timeline = {
  readonly __typename: 'Timeline';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly milestones?: Maybe<ReadonlyArray<Maybe<Milestone>>>;
  readonly canEdit?: Maybe<Scalars['Boolean']>;
};

export enum TimelineSubjectType {
  Portfolio = 'PORTFOLIO',
  HoldingCompany = 'HOLDING_COMPANY'
}


export type UpdateMilestoneMutation = {
  readonly __typename: 'UpdateMilestoneMutation';
  readonly milestone?: Maybe<Milestone>;
};

export type UpdateMilestonePayload = {
  readonly milestoneId: Scalars['ID'];
  readonly dueDate: Scalars['String'];
  readonly title: Scalars['String'];
  readonly body: Scalars['String'];
  readonly status: MilestoneStatus;
};

export type UpdatePortfolioDocumentTreeMutation = {
  readonly __typename: 'UpdatePortfolioDocumentTreeMutation';
  readonly documentTree?: Maybe<DocumentTree>;
};

export type UpdateSubpageMutation = {
  readonly __typename: 'UpdateSubpageMutation';
  readonly subpage?: Maybe<SubpageInterface>;
};


export type User = {
  readonly __typename: 'User';
  readonly id: Scalars['ID'];
  readonly isSuperuser: Scalars['Boolean'];
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly title: Scalars['String'];
  readonly location: Scalars['String'];
  readonly phoneNumber: Scalars['String'];
  readonly company?: Maybe<Company>;
  readonly companyName: Scalars['String'];
  readonly fullName?: Maybe<Scalars['String']>;
  readonly avatarUrl?: Maybe<Scalars['String']>;
  readonly hasSalesforceToken?: Maybe<Scalars['Boolean']>;
  readonly isSuperUser?: Maybe<Scalars['Boolean']>;
  readonly permissions?: Maybe<ReadonlyArray<UserPermissions>>;
  readonly organizations?: Maybe<ReadonlyArray<Organization>>;
  readonly lastActivity?: Maybe<Scalars['DateTime']>;
  readonly lastLogin?: Maybe<Scalars['DateTime']>;
  readonly notificationsEnabled?: Maybe<Scalars['Boolean']>;
  readonly agreements?: Maybe<UserAgreements>;
};


export type UserNotificationsEnabledArgs = {
  fundEntityId: Scalars['ID'];
  fundEntityType: FundEntityType;
};

export type UserAggs = {
  readonly __typename: 'UserAggs';
  readonly id: Scalars['ID'];
  readonly user?: Maybe<User>;
  readonly company?: Maybe<Company>;
  readonly sessionCount?: Maybe<Scalars['Int']>;
  readonly downloadCount?: Maybe<Scalars['Int']>;
  readonly viewCount?: Maybe<Scalars['Int']>;
};

export type UserAgreements = {
  readonly __typename: 'UserAgreements';
  readonly id: Scalars['ID'];
  readonly eulaRequired: Scalars['Boolean'];
  readonly acceptedEula: Scalars['Boolean'];
};

export type UserGroup = {
  readonly __typename: 'UserGroup';
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
  readonly role?: Maybe<Role>;
  readonly portfolioIds?: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly memberCount?: Maybe<Scalars['Int']>;
};

export type UserGroupMembership = {
  readonly __typename: 'UserGroupMembership';
  readonly id: Scalars['ID'];
  readonly user?: Maybe<User>;
  readonly userGroup?: Maybe<UserGroup>;
};

export type UserPermissions = {
  readonly __typename: 'UserPermissions';
  readonly name: PermissionType;
  readonly portfolioIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly holdingCompanyIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type UserType = {
  readonly __typename: 'UserType';
  readonly id?: Maybe<Scalars['ID']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly fullName?: Maybe<Scalars['String']>;
};

export type Utility = {
  readonly __typename: 'Utility';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly zone: Scalars['String'];
  readonly iso: Scalars['String'];
};

export enum WorkflowStatus {
  Draft = 'DRAFT',
  Acquired = 'ACQUIRED',
  Diligence = 'DILIGENCE',
  Sold = 'SOLD',
  Market = 'MARKET',
  Deleted = 'DELETED'
}

export type ZipDownloadOutput = {
  readonly __typename: 'ZipDownloadOutput';
  readonly exportable?: Maybe<ReadonlyArray<Maybe<Document>>>;
  readonly unexportable?: Maybe<ReadonlyArray<Maybe<Document>>>;
};
